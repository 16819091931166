<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    v-bind:style="{ zIndex: options.zIndex }"
  >
    <baseModal>
      <!-- <v-btn
        slot="positive-button"
        dark flat class="orange--text text-h6 font-weight-bold"
        @click.native="btnLogOutClick"
      >로그아웃</v-btn> -->

      <!-- <v-btn
        slot="negative-button"
        dark flat class="white--text subheading" @click.native="cancel">취소</v-btn> -->

      <v-toolbar-title
        slot="title-area"
        class="white--text">조직 생성|공유 안내</v-toolbar-title>
      <v-card-text
        slot="content"
      >
        <v-container grid-list-xs class="pb-0 mb-0">
          <v-row
            align="center"
            justify="center"
            no-gutters
          >
            <v-col cols="12" xs="12">
              <div class="text-body-2 mt-3 mb-2 green--text text--darken-1">
                <v-icon small color="green darken-1">info</v-icon>
                서비스를 이용하기 위해서는 연결된 조직이 필요합니다.
              </div>
            </v-col>
            <v-col cols="12" xs="12">
              <v-divider class="mb-3"></v-divider>
            </v-col>
            <v-col cols="12" xs="12">
              <v-expansion-panels popout focusable class="elevation-0">
                <v-expansion-panel>
                  <v-expansion-panel-header class="subheading mt-3 blue--text">
                    신규 조직 생성을 원하는 경우
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card :elevation="0">
                      <v-card-text>
                        <p>조직을 생성하여, 해당 조직의 모든 데이터를 소유하게 됩니다.</p>
                        <p>또한, 다른 구글계정자를 본 조직에 공유 또는 해제 할 수 있는 권한을 가지게 될 뿐만 아니라.</p>
                        <p>모든 조직원이 등록한 일체의 파일도 회원님의 구글드라이브에 저장됩니다.</p>
                        <v-btn
                          color="primary"
                          @click="setWorkGroupInfo"
                        >조직생성</v-btn>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header class="subheading mt-3 blue--text">
                    기존 조직 공유를 원하는 경우
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card :elevation="0">
                      <v-card-text>
                        <p>기존 조직에 소속되며, 해당 정보를 공유하게 됩니다.</p>
                        <p>이는 해당 조직의 관리(대행)자가 회원님을 기존 조직에 공유설정하여야  합니다.</p>
                        <p>그러므로, 기존 조직에 공유되기를 원하는 경우, 신규 조직 생성을 해서는 안됩니다.</p>
                        <v-btn
                          color="primary"
                          @click="requestWorkGroupShare"
                        >공유요청</v-btn>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header class="subheading mt-3 blue--text">
                    회원탈퇴를 원하는 경우
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card :elevation="0">
                      <v-card-text>
                        <p>회원님의 개인정보를 영구폐기합니다. 다만, 결제에 관련된 정보는 유지될 수 있습니다.</p>
                        <p>또한, 기존에 공유한 조직이 현존하는 경우, 회원님의 인사정보 및 업무관리정보는 해당 조직에 잔존합니다.</p>
                        <v-btn
                          color="primary"
                          @click="requestLeave"
                        >회원탈퇴</v-btn>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-row
                no-gutters
                align="center"
                justify="center"
                class="mt-6"
              >
                <v-btn
                  color="primary"
                  @click.stop="btnLogOutClick"
                >
                  <v-icon color="orange darken-1">mdi-power</v-icon>
                  로그아웃
                </v-btn>
              </v-row>
              <div class="mb-1" style="height: 30px;"></div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </baseModal>

    <!-- !! 확인 다이얼로그 : 이곳이 root 기 때문에 하위 어디서건 참조가 가능하다! -->
    <confirm-dialog ref="confirmDialog"></confirm-dialog>

  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

// NOTE: 이 페이지는 1회성 페이지다.
// 1. 회원가입만 되어있고 로그인 후 조직을 생성하거나 공유를 하는 선택을 하는게 첫 번째 목적이고
// 2. 위와 같은 상황에서 회원탈퇴를 신청할 수 있게 하는게 2번 째 목적이다.
// 때문에 소속 조직이 있는 경우는 이 페이지에 다시 올일이 없고, 조직 소속 후 회원탈퇴는 mypage 같은 메뉴에서 하게 된다.

import confirmDialog from '@/components/ConfirmDialog' // 확인 팝업

export default {
  components: {
    baseModal,
    confirmDialog // 확인 팝업
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    options: {
      color: 'primary',
      width: 650,
      zIndex: 300
    }
  }),

  mounted () {
    // this.dialog = true // FIXME: 디자인 테스트 끝나면 아래 주석 풀어야 함!

    // this.$route.query는 항상 참이다. token 여부를 체크할 때 에러가 나지 않도록 && 로 연결해야 한다
    if (this.$route.query && this.$route.query.token) {
      // 1) 정상적인 회원가입 프로세스일때 - token 이 쿼리에 담아져 올 때
      this.loginWithToken(this.$route.query.token)
      this.dialog = true
    } else if (this.$store.state.ui) {
      // 2) 이미 로그인된 상태라 저장소에 유저정보가 저장된 경우
      this.doRegistProc()
      this.dialog = true
    } else {
      // 이도저도 아니면 뭔가 잘못되었으니 초기화면으로
      this.redirect('/')
    }
  },

  methods: {
    dummy () {
    },
    sbpop (msg) {
      this.$store.commit('SB_POP', { msg })
    },
    // 구분: 토큰을 백엔드에서 넘겨받아 로그인 처리하는 함수
    async loginWithToken (token) {
      try {
        if (token) {
          // 로그인 : 토큰 저장 + 토큰을 푼 유저정보를 저장
          await this.$store.dispatch('LOGIN', { token })

          // 유저정보 패칭
          const ui = this.$store.state.ui
          if (ui.dbcode) {
            // 이미 몸담고 있는 조직이 있는 경우 스킵 - 리스트 페이지로 이동(차후 수정)
            this.redirect('/case')
          }
        } else {
          throw new Error('Token Not Reponsed : [401]')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 이미 로그인되어서 저장소에 유저정보가 있을 때!
    doRegistProc () {
      try {
        // 유저정보 패칭
        const ui = this.$store.state.ui
        if (ui.dbcode) {
          // 이미 몸담고 있는 조직이 있는 경우 스킵 - 리스트 페이지로 이동(차후 수정)
          this.redirect('/case')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 조직생성 버튼을 클릭시
    setWorkGroupInfo () {
      try {
        this.redirect('/auth/setWorkGroupInfo') // 조직생성 페이지로 이동
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 조직공유 요청 버튼을 클릭시
    requestWorkGroupShare () {
      try {
        this.redirect('/auth/requestLawWorkShare') // 조직공유 페이지로 이동
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 회원탈퇴 버튼을 클릭시
    requestLeave () {
      try {
        this.redirect('/auth/requestLeave') // 탈퇴 페이지로 이동
      } catch (e) {
        this.sbpop(e)
      }
    },
    redirect (to = '') {
      this.dialog = false
      this.$router.push(to)
    },
    // 구분:[2021.10.19] 실제 로그아웃 처리함수
    logOut () {
      this.$store.dispatch('LOGOUT')
        .then(() => {
          // !![2021.9.1]알림 팝업을 닫아주는 처리 추가
          this.msgSnackbar = false
          this.notifyOn = false

          this.$router.push('/')
        })
    },
    // 구분:[2021.10.19] 로그아웃 버튼 클릭 이벤트 핸들러
    async btnLogOutClick () {
      try {
        const msg = '로그아웃 하시겠습니까?<br>로워크의 로그아웃은<br><span class="primary--text">구글계정의 자동 로그아웃</span>을의미하는 것은 아닙니다.<br>(불필요시 구글계정도 로그아웃하십시오.)'
        if (await this.$refs.confirmDialog.open('로그아웃', msg, { color: 'error', width: 350 })) {
          this.logOut()
        }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
